import { Controller } from "stimulus"
import moment from 'moment'

export default class extends Controller {
  static targets = ["delivery", "deadline", "deadline_text"]

  sync(event) {
    event.preventDefault()
    var i = this.deliveryTarget.selectedIndex
    var deadlineTime = this.deliveryTarget.options[i].dataset.deadline
    this.deadlineTarget.value = deadlineTime
    this.deadline_textTarget.innerHTML = this.formatDate(deadlineTime)
  }

  formatDate(deadlineTime) {
    var deadline = new Date(deadlineTime)
    console.log(deadline)
    var d = moment(deadline)
    var month = d.month() + 1
    var date = d.date()
    var day = d.day()

    // //曜日の選択肢
    var youbi = new Array("(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)");
    // //出力用
    var formatDate = month + '/' + date + youbi[day] + ' ' + d.format('H:mm')

    return '締切: ' + formatDate
  }
}
