import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list"]

  remove(event) {
    event.preventDefault()
    this.listTarget.style.display = 'none'
    Turbolinks.clearCache()
  }

  error(event) {
    let [data, status, xhr] = event.detail;
    console.log(xhr.response)
  }
}
