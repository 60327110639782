import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]

  toggle(event) {
    event.preventDefault()
    if (event.target.checked) {
      this.add_association(event)
    } else {
      this.remove_association(event)
    }
  }

  add_association(event) {
    event.preventDefault()
    let item = event.target.parentNode.nextElementSibling
    if (item.classList.contains("nested-fields")) {
      item.querySelector("input[name*='_destroy']").value = 0
      item.style.display = 'block'
    } else {
      var content = event.target.parentNode.nextElementSibling.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
      event.target.parentNode.insertAdjacentHTML('afterend', content)
    }
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.parentNode.nextElementSibling
    item.querySelector("input[name*='_destroy']").value = 1
    if (item.querySelector("input[name*='id']").value ) {
      item.style.display = 'none'
    } else {
      item.remove()
    }
  }
}
