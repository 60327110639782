import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["plus", "minus", "number"]

  increment(event) {
    event.preventDefault()
    if (this.numberTarget.value != 100) {
      this.numberTarget.value++
    }
  }

  decrement(event) {
    event.preventDefault()
    if (this.numberTarget.value != 1) {
      this.numberTarget.value--
    }
  }
}
