// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "snackbarjs";
import "bootstrap-material-design"
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/js/all"

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "./change_order_supported";

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

FontAwesome.config.mutateApproach = 'sync'

// 初期化
document.addEventListener("turbolinks:load", function () {
  // BootstrapMaterialのJSを各ページで有効にする
  $('body').bootstrapMaterialDesign();
  // tooltipを有効化
  $('[data-toggle="tooltip"]').tooltip()
})

// flashをsnackbarで表示する
$(document).ready(function () {
  $.each(flashMessages, function (key, value) {
    if (key == "notice") {
      $.snackbar({ content: value, htmlAllowed: true, toggle: "snackbar", timeout: 5000 });
    } else {
      $.snackbar({ content: value, htmlAllowed: true, toggle: "snackbar", timeout: 0, style: key });
    }
  });
})
