$(document).on('click', '.js-supported', function () {
  var $elem = $(this)
  var url = $(this).data('url')

  $.ajax({
    url: url,
    type: 'PATCH',
    dataType: 'json'
  })
    .done((data) => {
      if (data) {
        //表示を対応済みにする
        $elem.empty();
        $elem.addClass('color-success');
        //アイコン(チェックマーク)を追加
        $elem.append("<i class='fas fa-check'></i>")
      } else {
        //表示を未対応にする
        $elem.html('未対応');
        $elem.removeClass('color-success');
      }
    })
    .fail((data) => {
      alert(data.message);
      console.log(data);
    })
});
