import { Controller } from "stimulus"
import moment from 'moment'

export default class extends Controller {
  static targets = ["start", "end", "current_month", "prev_month"]

  current_month(event) {
    event.preventDefault()
    this.prev_monthTarget.checked = false
    if (event.target.checked) {
      this.startTarget.value = moment().startOf('month').format('YYYY-MM-DD')
      this.endTarget.value = moment().endOf('month').format('YYYY-MM-DD')
    } else {
      this.startTarget.value = ''
      this.endTarget.value = ''
    }
  }

  prev_month(event) {
    event.preventDefault()
    this.current_monthTarget.checked = false
    if (event.target.checked) {
      this.startTarget.value = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      this.endTarget.value = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    } else {
      this.startTarget.value = ''
      this.endTarget.value = ''
    }
  }
}
