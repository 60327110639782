import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["course", "area"];

  initialize() {
    this.initialOptions = this.courseTarget.innerHTML;
  }

  updateCourseOptions(event) {
    const selectedAreaId = event.target.value;
    const courseOptions = this.getCourseOptions(selectedAreaId);
    this.areaTarget.innerHTML = courseOptions;
  }

  getCourseOptions(areaId) {
    const courses = this.getCoursesForArea(areaId);
    let optionsHTML = '<option> </option>';

    // コースのoption要素を追加
    optionsHTML += courses
      .map((course) => `<option value="${course.id}">${course.name}</option>`)
      .join("");

    return optionsHTML;
  }

  getCoursesForArea(areaId) {
    return this.courses.filter((course) => course.area_id == areaId);
  }

  get courses() {
    return JSON.parse(this.element.getAttribute("data-course-options"));
  }
}
