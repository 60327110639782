import { Controller } from "stimulus"
import moment from 'moment'

export default class extends Controller {
  static targets = ["number", "deadline_date"]

  connect() {
    this.deadline_dateTarget.innerHTML = this.calc(this.numberTarget.value, this.numberTarget.dataset.wday)
  }

  calc_date(event) {
    event.preventDefault()
    let item = event.target.nextElementSibling.nextElementSibling
    item.innerHTML = this.calc(event.target.value, event.target.dataset.wday)
  }

  calc(num, wday) {
    var today = moment().hour(0).minutes(0).second(0).day(wday)
    // 入力された数字から差分の時間を計算する
    var calc_date = today.subtract(num, 'hours')
    var you = calc_date.day()
    var hour = calc_date.hours()

    // //曜日の選択肢
    var youbi = new Array("日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日");
    // //出力用
    var day = youbi[you] + "の" + hour + "時が締切";

    return day
  }
}
